import { Grid, Paper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  Constants,
  VclApi,
  GlobalSettingsContext,
  getHostDependentImageUrl,
  AuthContext,
  MSALAuthService,
} from 'vcl-common';
import ContactCard from '../sharedComponents/contactCard';

export default function Contact() {
  const [regionInfo, setRegionInfo] = useState<any[]>([]);
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;
  const globalSettings: any = useContext(GlobalSettingsContext);

  useEffect(() => {
    const getData = async () => {
      const msalAuthToken = await authService.getAccessToken();
      const api = new VclApi(globalSettings.organization.id);
      const regions = await api.getAll(
        Constants.routes.api.regions,
        true,
        msalAuthToken,
      );
      const locations = await api.getAll(
        Constants.routes.api.locations,
        true,
        msalAuthToken,
      );

      setRegionInfo(
        regions.map((r: any) => {
          const locs = locations
            .filter((l: any) => l.regionId === r.id)
            .map((l) => {
              return {
                title: l.title,
                technicians: l.technicians.map((t: any) => t.user),
              };
            });
          return {
            title: r.title,
            locations: locs,
            admins: r.permissions.map((t: any) => t.user),
            thumbUrl: getHostDependentImageUrl(r.thumbnailUrl),
            heroUrl: getHostDependentImageUrl(r.heroUrl),
          };
        }),
      );
    };
    getData();
  }, [globalSettings, authService]);

  return (
    <>
      <h2>Contact</h2>
      {regionInfo.map((region) => {
        return (
          <>
            <Paper>
              <h3>{region.title}</h3>
              <Grid container spacing={3}>
                {region.admins.map((admin: any) => (
                  <Grid xs={12} md="auto">
                    <ContactCard
                      name={admin.fullName}
                      upn={admin.userName}
                      email={admin.userName}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3}>
                {region.locations.map((loc: any) => (
                  <Grid xs={12} md="auto">
                    {loc.title}
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </>
        );
      })}
    </>
  );

  // return (
  //   <>
  //     <Grid container spacing={2}>
  //       {regionInfo.map((region) => {
  //         return (
  //           <Grid xs="12" md="6">
  //             <Card sx={{ maxWidth: 345 }}>
  //               <CardHeader title={region.title} />
  //               <CardMedia
  //                 component="img"
  //                 height="194"
  //                 image={region.heroUrl}
  //                 alt={region.title}
  //               />
  //               <CardContent>
  //                 <Typography>Administrators</Typography>
  //                 {region.admins.map(admin => (<ContactCard name={admin.fullName} upn={admin.userName} email={admin.userName} />))}
  //               </CardContent>
  //             </Card>
  //           </Grid>
  //         );
  //       })}
  //     </Grid>
  //   </>
  // );
}
