import TextField from '@mui/material/TextField';
import { useState, useRef, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import LazyAutocomplete from '../sharedComponents/lazyAutocomplete';
import TabbedEditor from '../sharedComponents/tabbedEditor';
import TabPanel from '../sharedComponents/tabPanel';
import { AuthContext, Constants, MSALAuthService, VclApi } from 'vcl-common';
import { validateMetadata, validateRequired } from '../utils/validators';
import { EntityProp, initEntityMetadata } from '../utils/entityMetadata';
import PermissionList from '../sharedComponents/permissionList';
import { locationAvailablePermissions } from '../utils/permissions';

const initMetadata = (current?: any) =>
  initEntityMetadata(
    [
      new EntityProp('id', 0),
      new EntityProp('title', '', [validateRequired]),
      new EntityProp('locality', '', [validateRequired]),
      new EntityProp('region', null),
      new EntityProp('regionId', null, [validateRequired]),
    ],
    current,
  );

export default function Location() {
  const { itemId } = useParams() as any;

  const [activeTab, setActiveTab] = useState(0);
  const [metadata, setMetadata] = useState(initMetadata());
  const [canEdit, setCanEdit] = useState(false);
  const [technicians, setTechnicians] = useState<any[]>([]);
  const techniciansToUpdate = useRef([]);
  const techniciansToDelete = useRef([]);
  const techniciansToAdd = useRef<any[]>([]);
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;

  const loadMetadataCb = useCallback(
    async (metadata: { technicians: any[] }, setPermissions: any) => {
      // The technician objects need to be converted to "permissions" in order to use the permissionList component
      const technicianPerms = metadata.technicians?.map((tech) => {
        return {
          type: 0,
          permissionLevel: 2,
          id: tech.id,
          user: {
            fullName: tech.user.fullName,
          },
        };
      });
      setTechnicians(technicianPerms);
    },
    [],
  );

  const saveCb = useCallback(
    async (entityId: number) => {
      if (entityId <= 0) {
        console.error(
          'Missing entity ID, something must have gone wrong when saving the entity.',
        );
        return;
      }

      const msalAuthToken = await authService.getAccessToken();

      const providerId =
        techniciansToAdd.current.length > 0
          ? techniciansToAdd.current[0].identityProvider
          : -1;
      const api = new VclApi();

      try {
        if (techniciansToAdd.current.length > 0)
          await api.addTechnicians(
            Constants.routes.api.locations,
            entityId,
            providerId,
            techniciansToAdd.current,
            msalAuthToken,
          );

        if (techniciansToDelete.current.length > 0)
          await api.deleteTechnicians(
            Constants.routes.api.locations,
            entityId,
            techniciansToDelete.current,
            msalAuthToken as string,
          );
      } catch (error) {
        console.error(error);
      }
    },
    [authService],
  );

  const tabs = [
    {
      label: 'General',
      components: (
        <TabPanel value={activeTab} key="panel_0" index={0} mode="tabs">
          <TextField
            fullWidth
            required
            id="title"
            name="title"
            label="Title"
            value={metadata.title.value}
            error={!metadata.title.isValid}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
          />
          <TextField
            fullWidth
            multiline
            id="locality"
            name="locality"
            label="Location"
            value={metadata.locality.value}
            onChange={(event) => setMetadata(validateMetadata(metadata, event))}
            disabled={!canEdit}
            helperText='This can be the city, state or another locality. For example "Torslanda, Gothenburg"'
            required
            error={!metadata.locality.isValid}
          />
          <LazyAutocomplete
            initialValue={metadata.region?.value}
            label="Region"
            error={metadata.regionId.isValid === false}
            onNavigate={(item: { id: any }) =>
              window.open(`${Constants.routes.adminCenter.regions}/${item.id}`)
            }
            disabled={itemId !== 'new'}
            apiUrl={Constants.routes.api.regions}
            metadata={metadata}
            setMetadata={setMetadata}
            idColumnName="regionId"
            valColumnName="region"
            required
          />
        </TabPanel>
      ),
    },
    {
      label: 'Technicians',
      components: (
        <TabPanel value={activeTab} index={1} key="panel_1" mode={'tabs'}>
          <PermissionList
            permissionsToUpdate={techniciansToUpdate}
            permissionsToDelete={techniciansToDelete}
            permissionsToAdd={techniciansToAdd}
            permissions={technicians}
            setPermissions={setTechnicians}
            availablePermLevels={locationAvailablePermissions}
            canEdit={canEdit}
          />
        </TabPanel>
      ),
    },
  ];

  return (
    <TabbedEditor
      entityName="Location"
      apiUrl={Constants.routes.api.locations}
      entityRoute={Constants.routes.adminCenter.locations}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      metadata={metadata}
      setMetadata={setMetadata}
      canEdit={canEdit}
      canDelete={canEdit}
      setCanEdit={setCanEdit}
      usePermissions={false}
      initMetadataFn={initMetadata}
      entityId={itemId}
      saveCb={saveCb}
      loadMetadataCb={loadMetadataCb}
    />
  );
}
