import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  VclApi,
  formatAxiosError,
  AuthContext,
  MSALAuthService,
} from 'vcl-common';
import ConfirmDeleteDialog from './confirmDeleteDialog';
import PermissionList from './permissionList';
import SaveStateMessages from './saveStateMessages';
import TabbedContent from './tabbedContent';
import TabPanel from './tabPanel';
import {
  isCurrentUserEditorOfThis,
  savePermsAsync,
} from '../utils/permissions';
import { createEntityFromPageMetadata, PageState } from '../utils/saveProcess';
import { AxiosError } from 'axios';

type TabbedEditorProps = {
  activeTab?: number;
  setActiveTab?: any;
  metadata?: any;
  setMetadata?: any;
  canEdit?: boolean;
  canDelete?: boolean;
  canEditPermissions?: boolean;
  setCanEdit?: any;
  canSave?: boolean;
  usePermissions?: any;
  apiUrl?: any;
  initMetadataFn?: any;
  tabs?: any;
  entityRoute?: any;
  entityName?: any;
  entityId?: any;
  setMyRoles?: any;
  isDraft?: boolean;
  saveCb?: any;
  loadMetadataCb?: (metadata: any, setPermissions: any) => Promise<void>;
  myRoles?: any;
  customDeleteMsg?: string;
};

const TabbedEditor = (initialValues: TabbedEditorProps) => {
  const {
    activeTab,
    setActiveTab,
    metadata,
    setMetadata,
    canEdit,
    canDelete,
    canEditPermissions,
    setCanEdit,
    canSave,
    usePermissions,
    apiUrl,
    initMetadataFn,
    tabs,
    entityRoute,
    entityName,
    entityId,
    setMyRoles,
    saveCb,
    loadMetadataCb,
    customDeleteMsg,
  } = initialValues;

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;

  const api = useRef(new VclApi());
  const [saveProgress, setSaveProgress] = useState(PageState.UNTOUCHED);
  const [permissions, setPermissions] = useState<any>([]);
  const permissionsToUpdate = useRef([]);
  const permissionsToDelete = useRef([]);
  const permissionsToAdd = useRef([]);
  const [deleteDialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const enableValidation = useRef(false);
  const [saveError, setSaveError] = useState('');

  const isNewRequest = entityId === 'new';

  console.info('tabbedEditor | pageState: ' + saveProgress);

  const fetchDataAndInitPage = useCallback(async () => {
    const msalAuthToken = await authService.getAccessToken();
    try {
      const response: any = await api.current.getById(
        apiUrl,
        entityId,
        true,
        msalAuthToken,
      );

      setMetadata(initMetadataFn(response.data));
      setCanEdit(isCurrentUserEditorOfThis(response.data.currentUserRoles));

      // The myRoles state is not used by all parent components, so check first if it exists
      if (setMyRoles) {
        setMyRoles(response.data.currentUserRoles);
      }

      if (usePermissions) {
        setPermissions(response.data.permissions);
      }

      if (loadMetadataCb) await loadMetadataCb(response.data, setPermissions);
    } catch (error) {
      console.error(error);
    }
  }, [
    apiUrl,
    entityId,
    initMetadataFn,
    loadMetadataCb,
    authService,
    setCanEdit,
    setMetadata,
    setMyRoles,
    usePermissions,
  ]);

  // Get metadata and set edit state based upon the user's role
  useEffect(() => {
    console.info('tabbedEditor | useEffect 1');

    const getData = async () => {
      const msalAuthToken = await authService.getAccessToken();
      if (!isNewRequest) {
        if (msalAuthToken) await fetchDataAndInitPage();
        setLoading(false);
        enableValidation.current = true;
      } else {
        setCanEdit(true);
        setLoading(false);
        enableValidation.current = true;
      }
    };
    getData();
  }, [fetchDataAndInitPage, isNewRequest, authService, setCanEdit]);

  // Set save button state based upon metadata validation
  useEffect(() => {
    console.info('tabbedEditor | useEffect 2');
    // Wait with validation until the page is loaded
    if (!enableValidation.current) return;

    let isPageValid = true;

    for (let i = 0; i < Object.keys(metadata).length; i++) {
      const key = Object.keys(metadata)[i];
      if (!metadata[key].isValid) {
        isPageValid = false;
        break;
      }
    }

    if (isPageValid) {
      setSaveProgress(PageState.DIRTY);
    } else {
      setSaveProgress(PageState.VALIDATION_ERROR);
    }
  }, [metadata]);

  useEffect(() => {
    if (metadata.locationId && metadata.location.value && usePermissions) {
      let updatedPerms: any[] = [];
      if (metadata.permissions.value) {
        updatedPerms = [...metadata.permissions.value];
      }
      updatedPerms = updatedPerms.concat(
        metadata.location.value.technicians.map((t: any) => {
          return {
            id: t.userId,
            permissionLevel: 2,
            user: {
              fullName: t.user.fullName,
            },
            isInheritedTechnician: true,
            inheritedTechnicianLocation: metadata.location.value.title,
          };
        }),
      );
      setPermissions(updatedPerms);
    }
  }, [
    metadata.locationId,
    metadata.location,
    metadata.permissions,
    usePermissions,
  ]);

  const handleSaveSuccess = useCallback(
    (newId: any) => {
      navigate(`${entityRoute}`);
    },
    [entityRoute, navigate],
  );

  const saveEntity = useCallback(async () => {
    const entity = createEntityFromPageMetadata(metadata);
    const saveFn = isNewRequest ? api.current.post : api.current.put;
    let savedItemId = 0;
    const msalAuthToken = await authService.getAccessToken();
    try {
      const response: any = await saveFn(apiUrl, entity, null, msalAuthToken);
      savedItemId = entity.id <= 0 ? response.data.id : entity.id;
      if (usePermissions) {
        await savePermsAsync(
          apiUrl,
          savedItemId,
          permissionsToUpdate.current,
          api.current.updatePermission,
          permissionsToDelete.current,
          api.current.deletePermissions,
          permissionsToAdd.current,
          api.current.addPermissions,
          metadata.availablePermissionLevels.value,
          msalAuthToken,
        );
      }
      handleSaveSuccess(savedItemId);
    } catch (error: unknown | AxiosError) {
      setSaveError(formatAxiosError(error));
      setSaveProgress(PageState.SAVE_ERROR);
    } finally {
      if (saveCb) await saveCb(savedItemId);
    }
  }, [
    apiUrl,
    handleSaveSuccess,
    isNewRequest,
    metadata,
    authService,
    saveCb,
    usePermissions,
  ]);

  const handleTabChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const handleSaveClick = (event: any) => {
    setSaveProgress(PageState.SAVING);
  };

  const handleCancelClick = (event: any) => {
    navigate(entityRoute);
  };

  const handleDeleteClick = useCallback(
    async (event: any) => {
      const msalAuthToken = await authService.getAccessToken();
      try {
        await api.current.delete(apiUrl, { id: entityId }, msalAuthToken);
        navigate(entityRoute);
      } catch (error: unknown | AxiosError) {
        setSaveError(formatAxiosError(error));
        setSaveProgress(PageState.SAVE_ERROR);
        setDialogOpen(false);
      }
    },
    [apiUrl, entityId, entityRoute, navigate, authService],
  );

  let tabSections = tabs;
  if (usePermissions) {
    tabSections = [
      ...tabs,
      {
        label: 'Permissions',
        components: (
          <TabPanel
            value={activeTab}
            index={tabs.length}
            key={`panel_${tabs.length}`}
            mode={'tabs'}
          >
            <PermissionList
              permissionsToUpdate={permissionsToUpdate}
              permissionsToDelete={permissionsToDelete}
              permissionsToAdd={permissionsToAdd}
              permissions={permissions}
              setPermissions={setPermissions}
              availablePermLevels={metadata.availablePermissionLevels.value}
              canEdit={canEditPermissions}
              disabled={
                !canEdit || (canSave !== undefined && canSave === false)
              }
              entity={entityName}
              metadata={metadata}
            />
          </TabPanel>
        ),
      },
    ];
  }

  return (
    <>
      <SaveStateMessages
        updateProgess={(newState: any) => setSaveProgress(newState)}
        saveState={saveProgress}
        saveAction={() => saveEntity()}
        entityTypeName={entityName}
        errorMessage={saveError}
      />
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <h2>{entityName}</h2>
        </Grid>
      </Grid>
      <TabbedContent
        sections={tabSections}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        onFinish={handleSaveClick}
        mode={'tabs'}
        loading={loading}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          id="buttonsGrid"
        >
          <Button
            name="saveButton"
            id="saveButton"
            variant="contained"
            disabled={
              saveProgress !== PageState.DIRTY ||
              !canEdit ||
              (canSave !== undefined && canSave === false)
            }
            onClick={handleSaveClick}
            color="secondary"
          >
            Save
          </Button>
          <Button
            name="cancelButton"
            id="cancelButton"
            variant="outlined"
            disabled={saveProgress > PageState.DIRTY}
            onClick={handleCancelClick}
            color="secondary"
          >
            Cancel
          </Button>
          {!isNewRequest && (
            <Button
              name="deleteButton"
              id="deleteButton"
              variant="contained"
              disabled={
                !canEdit ||
                !canDelete ||
                (canSave !== undefined && canSave === false)
              }
              onClick={() => setDialogOpen(true)}
              color="error"
            >
              Delete
            </Button>
          )}
        </Grid>
      </TabbedContent>
      <ConfirmDeleteDialog
        show={deleteDialogOpen}
        title={metadata?.title?.value}
        onConfirm={handleDeleteClick}
        onClose={() => setDialogOpen(false)}
        customDeleteMsg={customDeleteMsg}
      />
    </>
  );
};

export default TabbedEditor;
