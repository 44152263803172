import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext } from 'react';
import LookupLink from '../sharedComponents/lookupLink';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import {
  Constants,
  GlobalSettingsContext,
  getHostDependentImageUrl,
} from 'vcl-common';
import { Avatar } from '@mui/material';

export default function Channels() {
  const globalSettings: any = useContext(GlobalSettingsContext);

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = [
    {
      field: 'thumbnailUrl',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params: { value: string }) => {
        return <Avatar src={getHostDependentImageUrl(params.value)} alt="" />;
      },
    },
    { field: 'title', headerName: 'Title', width: 250, sortable: false },
    {
      field: 'isOpen',
      headerName: 'Visibility',
      width: 200,
      sortable: false,
      renderCell: (params: { value: boolean }) => {
        return params.value
          ? 'Internal + external users'
          : 'Internal users only';
      },
    },
    {
      field: 'relativeUrl',
      headerName: 'Link',
      width: 300,
      sortable: false,

      valueFormatter: (params: { value: string }) => {
        return params && params.value ? params.value : '';
      },
      renderCell: (params: { value: string }) => {
        if (params && params.value) {
          const url = `${process.env['REACT_APP_PUBLICWEB_URL'] as string}${
            params.value
          }`;
          return (
            <LookupLink link={url} displayText={params.value} openInNewTab />
          );
        } else return null;
      },
      disableClickEventBubbling: true,
      valueParser: (value: any) => value,
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 150,
      sortable: false,
      valueFormatter: (params: { title: any | null }) => {
        return params && params.title ? params.title : '';
      },
      renderCell: (params: { value: { id: any; title: any } | null }) => {
        if (params && params.value) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.regions}/${params.value.id}`}
              displayText={params.value.title ?? ''}
            />
          );
        } else return null;
      },
      disableClickEventBubbling: true,
      valueParser: (value: any) => value,
      hide: isXSmallScreen,
    },
  ];

  return (
    <VCLDataGrid
      title="Channels"
      entityRoute={Constants.routes.adminCenter.channels}
      apiUrl={Constants.routes.api.channels}
      getPersonalizedData={true}
      gridColumns={columns}
      canUserAdd={globalSettings.userInfo.isRegionAdminOfAny}
      canUserViewGrid={globalSettings.userInfo.isChannelAdminOfAny}
    />
  );
}
