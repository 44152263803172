import { createContext, useState } from 'react';
import AddIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import AADUserSearch from './aadUserSearch';
import AddUsers from './addUsers';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&:last-of-type': {
      marginLeft: theme.spacing(2),
      color: theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      '&.Mui-disabled': {
        border: '1px',
      },
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

type PermissionListProps = {
  permissions: any[];
  setPermissions: any;
  availablePermLevels: any[];
  permissionsToUpdate: any;
  permissionsToDelete: any;
  permissionsToAdd: any;
  canEdit: any;
  disabled?: any;
  entity?: string;
  metadata?: any;
};

type PermissionContextProps = {
  entity?: string;
  metadata?: any;
};

export const PermissionContext = createContext<PermissionContextProps>({
  entity: '',
  metadata: null,
});

export default function PermissionList(props: PermissionListProps) {
  const {
    permissions,
    setPermissions,
    availablePermLevels,
    permissionsToUpdate,
    permissionsToDelete,
    permissionsToAdd,
    canEdit,
    disabled,
  } = props;

  const [addDialogIsOpen, setOpen] = useState(false);
  const [showAddUsers, setShowAddUsers] = useState(false);

  const handlePermissionChange = (
    event: any,
    value: any,
    permissionToChange: any,
  ) => {
    if (value === null || value === undefined) {
      return;
    }

    if (value === 'del') {
      handlePermissionRemoval(permissionToChange.id);
      return;
    }

    const updatedPermissions = [...permissions].map((item, index) => {
      if (item.id === permissionToChange.id) {
        return { ...item, permissionLevel: value };
      }
      return item;
    });
    setPermissions(updatedPermissions);

    if (Number.isInteger(permissionToChange.id)) {
      // This is a permission that is already persisted in the database.
      // It should be updated - make sure it does not get addedto update array more than once.
      const newSelection = [...permissionsToUpdate.current].filter((p) => {
        return p.id !== permissionToChange.id;
      });

      const updatedPermission = permissionToChange;
      updatedPermission.permissionLevel = value;

      newSelection.push(updatedPermission);
      permissionsToUpdate.current = newSelection;
    } else {
      // If we are changing permission level for a not-yet-persisted user, we need to make sure
      // the new user is added with this updated permission level instead of the level originally selected.
      const updatedAddPermissions = [...permissionsToAdd.current].map((p) => {
        if (p.federatedUserId !== permissionToChange.id) {
          return p;
        } else {
          const updatedItem = p;
          updatedItem.permLevelId = value;
          return updatedItem;
        }
      });

      permissionsToAdd.current = updatedAddPermissions;
    }
  };

  const handlePermissionRemoval = (permissionId: any) => {
    const updatedPermissions = [...permissions].filter((item, index) => {
      return item.id !== permissionId;
    });
    setPermissions(updatedPermissions);

    if (Number.isInteger(permissionId)) {
      if (permissionsToDelete.current.indexOf(permissionId) < 0) {
        permissionsToDelete.current = [...permissionsToDelete.current].concat([
          permissionId,
        ]);
      }
    } else {
      const updatedAddPermissions = [...permissionsToAdd.current].filter(
        (p) => {
          return p.federatedUserId !== permissionId;
        },
      );
      permissionsToAdd.current = updatedAddPermissions;
    }
  };

  const handleNewUserPermissions = (
    userList: any,
    permissionId: any,
    identityProvider: any = 1,
  ) => {
    const usertype = 0; // Groups not yet supported
    const newMockPermissions = userList
      .filter((u: any) => !permissions.some((perm: any) => perm.id === u.id))
      .map((u: any) => {
        return {
          id: u.id,
          type: usertype,
          permissionLevel: permissionId,
          user: {
            fullName: u.displayName,
          },
        };
      });
    const updatedPermissions = [...permissions].concat(newMockPermissions);
    setPermissions(updatedPermissions);

    const addPermBatch = userList.map((u: any) => {
      return {
        permLevelId: permissionId,
        fullName: u.displayName,
        userName: u.userPrincipalName,
        federatedUserId: u.id,
        identityProvider: u.identityProvider
          ? u.identityProvider
          : identityProvider,
      };
    });
    permissionsToAdd.current = [...permissionsToAdd.current].concat(
      addPermBatch,
    );
  };

  const permissionToggle = (permission: any) => {
    return (
      <StyledToggleButtonGroup
        color="primary"
        value={permission.permissionLevel}
        exclusive
        key={`toggle-${permission.id}`}
        onChange={(event, newLevel) => {
          handlePermissionChange(event, newLevel, permission);
        }}
      >
        {availablePermLevels.map((level: any) => {
          return (
            <ToggleButton key={`btn-${level.id}`} size="small" value={level.id}>
              {level.title}
            </ToggleButton>
          );
        })}
        {canEdit && (
          <ToggleButton key="btn-del" size="small" value="del">
            <DeleteIcon />
          </ToggleButton>
        )}
      </StyledToggleButtonGroup>
    );
  };

  return (
    <>
      <PermissionContext.Provider
        value={{
          entity: props.entity,
          metadata: props.metadata,
        }}
      >
        {addDialogIsOpen && (
          <AADUserSearch
            show={addDialogIsOpen}
            validPermissionLevels={availablePermLevels}
            onClose={() => setOpen(false)}
            onAdd={(users: any, permissionLevel: any, identityProvider = 1) =>
              handleNewUserPermissions(users, permissionLevel, identityProvider)
            }
          />
        )}
        {showAddUsers && (
          <AddUsers
            show={showAddUsers}
            validPermissionLevels={availablePermLevels}
            onClose={() => setShowAddUsers(false)}
            onAdd={(users: any, permissionLevel: any, identityProvider = 1) =>
              handleNewUserPermissions(users, permissionLevel, identityProvider)
            }
          />
        )}
        {canEdit && (
          <>
            <Button
              color="secondary"
              size="small"
              sx={{ ml: 1, mb: 2 }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
              disabled={disabled}
            >
              Add
            </Button>
            <Button
              color="secondary"
              size="small"
              sx={{ ml: 1, mb: 2 }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setShowAddUsers(true)}
            >
              Add Multiple
            </Button>
          </>
        )}
        {permissions.some((p: any) => !p.isInheritedTechnician) && (
          <Typography fontWeight={'bold'}>Direct permissions</Typography>
        )}
        <List
          sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}
        >
          {permissions
            .filter((p: any, index: any) => {
              const hasAllowedLevel =
                availablePermLevels.filter((apl: any, index: any) => {
                  return apl.id === p.permissionLevel;
                }).length > 0;
              return (
                p.user !== null && hasAllowedLevel && !p.isInheritedTechnician
              );
            })
            .map((perm: any) => {
              const labelId = `checkbox-list-label-${perm.id}`;
              return (
                <ListItem key={`item-${perm.id}`}>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <ListItemText
                        id={labelId}
                        primary={perm.user.fullName}
                        sx={{
                          '& .MuiListItemText-primary': {
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          },
                        }}
                        primaryTypographyProps={{ noWrap: false }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ListItemSecondaryAction>
                        {permissionToggle(perm)}
                      </ListItemSecondaryAction>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
        </List>
        {permissions.some((p: any) => p.isInheritedTechnician) && (
          <>
            <Typography fontWeight="bold" component="span" mr={0.5}>
              Technicians
            </Typography>
            <Typography component="span">
              {'(inherited from ' +
                permissions.filter((p: any) => p.isInheritedTechnician)[0]
                  .inheritedTechnicianLocation +
                ')'}
            </Typography>
            <List
              sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}
            >
              {permissions
                .filter((p: any) => p.isInheritedTechnician)
                .map((perm: any) => {
                  const labelId = `checkbox-list-label-${perm.id}`;
                  return (
                    <ListItem key={`item-${perm.id}`}>
                      <ListItemText id={labelId} primary={perm.user.fullName} />
                    </ListItem>
                  );
                })}
            </List>
          </>
        )}
      </PermissionContext.Provider>
    </>
  );
}
