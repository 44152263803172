import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from 'tss-react/mui';
import { format, parseISO } from 'date-fns';
import { useContext } from 'react';
import LookupLink from '../sharedComponents/lookupLink';
import StatusChip from '../sharedComponents/statusChip';
import VCLDataGrid from '../sharedComponents/vclDataGrid';
import {
  UIContext,
  Constants,
  getHostDependentImageUrl,
  GlobalSettingsContext,
  IGlobalSettings,
} from 'vcl-common';
import { Avatar } from '@mui/material';

export const useStyles = makeStyles()((theme) => ({
  vclDataGrid: {
    '& .row-style--deleted': {
      filter: 'opacity(30%)',
    },
    '& .row-style--draft': {
      // This style is left for possible future approval feature
      backgroundColor: '#FEF9E7',
    },
  },
}));

export const webcastfilters = [
  {
    name: 'past',
    title: 'Past',
    filter: {
      includeCurrent: false,
      includeFuture: false,
      includePast: true,
      isDeleted: false,
      searchText: '',
      orderBy: 'startTime',
      orderByDescending: true,
    },
  },
  {
    name: 'current',
    title: 'Current',
    filter: {
      includeCurrent: true,
      includeFuture: true,
      includePast: false,
      isDeleted: false,
      searchText: '',
      orderBy: 'startTime',
      orderByDescending: false,
    },
  },
  {
    name: 'all',
    title: 'All',
    filter: {
      includeCurrent: true,
      includeFuture: true,
      includePast: true,
      isDeleted: false,
      searchText: '',
      orderBy: 'startTime',
      orderByDescending: false,
    },
  },
  {
    name: 'deleted',
    title: 'Deleted',
    filter: {
      includeCurrent: true,
      includeFuture: true,
      includePast: true,
      isDeleted: true,
      searchText: '',
      orderBy: 'startTime',
      orderByDescending: true,
    },
  },
];

export default function Webcasts() {
  const globalSettings = useContext<IGlobalSettings>(GlobalSettingsContext);
  const uiContext: any = useContext(UIContext);
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { classes } = useStyles();

  const getEntityTitle = (params: { value: { title: any } | null }) => {
    if (params && params.value && params.value.title !== null)
      return params.value.title;
    else return '-1';
  };

  const columns = [
    {
      field: 'thumbnailUrl',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params: { value: string }) => {
        return <Avatar src={getHostDependentImageUrl(params.value)} alt="" />;
      },
    },
    {
      field: 'titleCombined',
      headerName: 'Title',
      width: 200,
      sortable: false,
    },
    {
      field: 'startTime',
      headerName: 'Start Date',
      width: 180,
      type: 'dateTime',
      valueFormatter: (params: { value: string | null }) => {
        return params && params.value
          ? format(parseISO(params + 'Z'), 'P p', {
              locale: uiContext.currentLocale,
            })
          : null;
      },
      hide: isXSmallScreen,
      sortable: false,
    },
    {
      field: 'endTime',
      headerName: 'End Date',
      width: 180,
      type: 'dateTime',
      valueFormatter: (params: { value: string | null }) => {
        return params && params.value
          ? format(parseISO(params + 'Z'), 'P p', {
              locale: uiContext.currentLocale,
            })
          : null;
      },
      hide: isSmallScreen,
      sortable: false,
    },
    {
      field: 'viewingStateString',
      headerName: 'State',
      width: 150,
      renderCell: (params: { value: string }) => {
        return <StatusChip statusText={params.value} />;
      },
      hide: isSmallScreen,
      sortable: false,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 150,
      valueFormatter: (params: { title: any | null }) => {
        return params && params.title ? params.title : '';
      },
      renderCell: (params: { value: { id: any; title: any } | null }) => {
        if (params && params.value) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.locations}/${params.value.id}`}
              displayText={params.value.title ?? ''}
            />
          );
        } else return null;
      },
      disableClickEventBubbling: true,
      valueParser: (value: any) => value,
      sortComparator: (v1: any, v2: any, cellParams1: any, cellParams2: any) =>
        getEntityTitle(cellParams1).localeCompare(getEntityTitle(cellParams2)),
      hide: isXSmallScreen,
      sortable: false,
    },
    {
      field: 'channel',
      headerName: 'Channel',
      width: 150,
      valueFormatter: (params: { title: any | null }) => {
        return params && params.title ? params.title : '';
      },
      renderCell: (params: { value: { id: any; title: any } | null }) => {
        if (params && params.value) {
          return (
            <LookupLink
              link={`${Constants.routes.adminCenter.channels}/${params.value.id}`}
              displayText={params.value.title ?? ''}
            />
          );
        } else return null;
      },
      disableClickEventBubbling: true,
      valueParser: (value: any) => value,
      sortComparator: (v1: any, v2: any, cellParams1: any, cellParams2: any) =>
        getEntityTitle(cellParams1).localeCompare(getEntityTitle(cellParams2)),
      hide: isXSmallScreen,
      sortable: false,
    },
  ];

  return (
    <VCLDataGrid
      title="Webcasts"
      entityRoute={Constants.routes.adminCenter.webcasts}
      apiUrl={Constants.routes.api.webcasts}
      getPersonalizedData={true}
      gridColumns={columns}
      canUserAdd={globalSettings.userInfo?.isChannelAdminOfAny ?? false}
      filters={webcastfilters}
      defaultFilterName="current"
      classes={classes}
      viewRoute={Constants.routes.adminCenter.webcastSummary}
      canUserViewGrid={globalSettings.userInfo?.isWebcastAdminOfAny ?? false}
    />
  );
}
