import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  VclApi,
  GlobalSettingsContext,
  formatAxiosError,
  AuthContext,
  MSALAuthService,
} from 'vcl-common';
import AddButton from './addButton';
import AuditLogListing from './auditLogListing';
import MessageBar, { Types } from './messageBar';
import { AxiosError } from 'axios';
import SearchBar from './searchBar';

interface VCLDataGridProps {
  title: string;
  entityRoute?: string;
  apiUrl: string;
  getPersonalizedData: boolean;
  gridColumns: any[];
  canUserAdd: boolean;
  filters?: any;
  defaultFilterName?: string;
  classes?: any;
  viewRoute?: string;
  showSearchBar?: boolean;
  showSelectOption?: boolean;
  canUserViewGrid: boolean;
  disableRowClick?: boolean;
  onAddClick?: any;
  initialSelectedIds?: any[];
  getSelectedIds?: (ids: any[]) => void;
  className?: string;
}

const VCLDataGrid = (initialItems: VCLDataGridProps) => {
  const {
    title,
    entityRoute,
    apiUrl,
    getPersonalizedData,
    gridColumns,
    canUserAdd,
    filters,
    defaultFilterName,
    classes,
    viewRoute,
    showSearchBar = true,
    canUserViewGrid,
    disableRowClick = false,
    onAddClick = null,
    showSelectOption = false,
    initialSelectedIds = null,
    getSelectedIds = null,
    className = 'dataGridContainer',
  } = initialItems;

  const settingsContext: any = useContext(GlobalSettingsContext);
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [historySelectedId, setHistorySelected] = useState(null);
  const [filter, setFilter] = useState(defaultFilterName);
  const [dialog, setDialog] = useState({ type: '', message: '' });
  const pageSize = settingsContext.siteSettings.defaultAPIPageSize;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: settingsContext.siteSettings.defaultAPIPageSize,
  });
  const [searchText, setSearchText] = useState('');
  const api = useRef(new VclApi(settingsContext.organization.id));

  const [selectedRows, setSelectedRows] = useState([]);

  if (!canUserViewGrid && dialog.type === '') {
    setDialog((current) => {
      const nState = { ...current };
      nState.type = Types.error;
      nState.message = 'You do not have permissions to view this data.';
      return nState;
    });
  }

  useEffect(() => {
    console.info('vclDataGrid | useEffect');
    (async () => {
      const msalAuthToken = await authService.getAccessToken();
      if (!msalAuthToken || !canUserViewGrid) return;

      setIsLoading(true);

      let selectedFilter = null;
      if (filter && filter.length > 0 && filters && filters.length > 0) {
        const filterMatch = filters.filter((f: any) => f.name === filter);
        selectedFilter =
          filterMatch && filterMatch.length > 0 ? filterMatch[0].filter : null;
        selectedFilter['searchText'] = searchText;
      } else if (showSearchBar) {
        if (searchText && searchText.length > 0) {
          selectedFilter = { searchText: `${searchText}` };
        } else {
          selectedFilter = null;
        }
      }

      try {
        const response: any = getPersonalizedData
          ? await api.current.getMyBatch(
              apiUrl,
              paginationModel.page + 1,
              selectedFilter,
              true,
              msalAuthToken,
            )
          : await api.current.getAllBatch(
              apiUrl,
              paginationModel.page + 1,
              selectedFilter,
              true,
              msalAuthToken,
            );

        let _webcasts = response.data.items;
        let _count = response.data.properties.totalItemCount;
        if (
          _webcasts &&
          _webcasts.length > 0 &&
          initialSelectedIds &&
          initialSelectedIds.length > 0
        ) {
          _webcasts = _webcasts.filter(
            (webcast: any) => !initialSelectedIds.includes(webcast.id),
          );
          _count = response.data.totalItems - initialSelectedIds.length;
        }
        setRowCount(_count);
        setRows(_webcasts);
      } catch (error: unknown | AxiosError) {
        console.error(error);
        setDialog((current) => {
          const nState = { ...current };
          nState.type = Types.error;
          nState.message = `Error occured when loading the requested data: ${formatAxiosError(
            error,
          )}`;
          return nState;
        });
      } finally {
        setIsLoading(false);
      }
    })();
    return () => setRows([]);
  }, [
    apiUrl,
    canUserViewGrid,
    filter,
    filters,
    searchText,
    getPersonalizedData,
    authService,
    paginationModel.page,
    showSearchBar,
    initialSelectedIds,
  ]);

  const getFilterButtons = () => {
    return filters.map((f: any) => {
      return (
        <ToggleButton value={f.name} key={f.name}>
          {f.title}
        </ToggleButton>
      );
    });
  };

  const handleRowClick = (params: any) => {
    if (disableRowClick) return;
    if (viewRoute) {
      navigate(`${viewRoute}/${params.id}`);
    } else {
      navigate(`${entityRoute}/${params.id}`);
    }
  };

  const handleAddClick = (event: any) => {
    if (onAddClick) {
      onAddClick();
      return;
    }
    navigate(`${entityRoute}/new`);
  };

  const handleViewChange = (event: any, newView: any) => {
    // prevent to click when loading is happening
    if (isLoading === false) setFilter(newView);
  };

  function onConfirmButtonClick() {
    setDialog((current) => {
      const nState = { ...current };
      nState.message = '';
      return nState;
    });
  }

  const handleSelectionChange = (newSelection: any) => {
    if (getSelectedIds) {
      setSelectedRows(newSelection);
      getSelectedIds(newSelection);
    }
  };

  return (
    <>
      {canUserViewGrid && (
        <div className={`view ${classes && classes.vclDataGrid} mouseIconP`}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={2} lg={4}>
              <h2>{title}</h2>
            </Grid>
            {filters && filters.length > 0 && (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <ToggleButtonGroup
                  size="small"
                  color="secondary"
                  value={filter}
                  exclusive
                  onChange={handleViewChange}
                >
                  {getFilterButtons()}
                </ToggleButtonGroup>
              </Grid>
            )}
            {showSearchBar && (
              <Grid item xs={12} sm={6} md={6} lg={4} textAlign="right">
                <SearchBar
                  onSearch={(searchText: string) => {
                    setSearchText(searchText);
                  }}
                ></SearchBar>
              </Grid>
            )}
          </Grid>
          <div className={className}>
            <div className="vcldgflexGrow">
              <DataGrid
                disableColumnFilter
                disableRowSelectionOnClick={disableRowClick}
                onRowClick={handleRowClick}
                columns={gridColumns}
                rows={rows}
                loading={isLoading}
                rowCount={rowCount}
                pageSizeOptions={[pageSize]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                checkboxSelection={showSelectOption}
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectedRows}
                getRowClassName={(params: any) =>
                  `row-style--${params.row.isDeleted ? 'deleted' : 'normal'}`
                }
              />
              {canUserAdd && <AddButton handleClick={handleAddClick} />}
              <Dialog
                fullScreen={false}
                maxWidth={'lg'}
                open={historySelectedId !== null}
                onClose={() => setHistorySelected(null)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">Audit Log</DialogTitle>
                <DialogContent dividers={false}>
                  <AuditLogListing
                    api={api.current}
                    type="subnet"
                    id={historySelectedId}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setHistorySelected(null)}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              {canUserAdd && <AddButton handleClick={handleAddClick} />}
              <Dialog
                fullScreen={false}
                maxWidth={'lg'}
                open={historySelectedId !== null}
                onClose={() => setHistorySelected(null)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">Audit Log</DialogTitle>
                <DialogContent dividers={false}>
                  <AuditLogListing
                    api={api.current}
                    type="subnet"
                    id={historySelectedId}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setHistorySelected(null)}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      )}
      {dialog.message && (
        <MessageBar
          confirmationType={dialog.type}
          message={dialog.message}
          delayTimeMS={1500}
          reset={dialog.type === Types.error ? 'true' : ''}
          open={true}
          buttonText="Close"
          onButtonClick={onConfirmButtonClick}
          performAction={() =>
            setDialog((current) => {
              const nState = { ...current };
              nState.message = '';
              return nState;
            })
          }
        />
      )}
    </>
  );
};

export default VCLDataGrid;
