import { useMsal } from '@azure/msal-react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { useContext, useRef, useState } from 'react';
import {
  AuthContext,
  Constants,
  MSALAuthService,
  VclApi,
  getProviderOptions,
} from 'vcl-common';
import { protectedResources } from '../utils/msalConfig';
import { InteractionStatus } from '@azure/msal-browser';
import { PermissionContext } from './permissionList';

const columns = [
  { id: 'displayName', label: 'Display Name', minWidth: 170 },
  { id: 'mail', label: 'Email', minWidth: 100 },
];

type AADUserSearchProps = {
  show: boolean;
  onAdd: any;
  validPermissionLevels: any[];
  onClose: any;
};

export default function AADUserSearch(props: AADUserSearchProps) {
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;
  const permissionContext = useContext(PermissionContext);
  const { instance, inProgress } = useMsal();
  const providerOptions = getProviderOptions(
    instance,
    protectedResources.graphMe.scopes,
  );

  const { show, onAdd, validPermissionLevels, onClose } = props;
  const [results, setResults] = useState<any[]>([]);
  const [usersToAdd, setUserCollection] = useState<any[]>([]);
  const [permissionLevel, setLevel] = useState(validPermissionLevels[0]);
  const lastNoOfKeyStrokes = useRef(0);
  const lastQuery = useRef(null);
  const [isExternalUsers, setIsExternalUsers] = useState<boolean>(false);
  const [externalUser, setExternalUser] = useState<any>('');

  const search = async (
    query: string | any[] | null,
    currentNoOfKeyStrokes: number,
  ) => {
    if (
      !query ||
      query.length < 4 ||
      query !== lastQuery.current ||
      currentNoOfKeyStrokes === 0 ||
      currentNoOfKeyStrokes < lastNoOfKeyStrokes.current
    ) {
      return;
    }

    try {
      const msalAuthToken = await authService.getAccessToken();

      const api = new VclApi();
      const response: any = await api.get(
        Constants.routes.api.userssearch,
        {
          query,
        },
        true,
        msalAuthToken,
      );

      lastNoOfKeyStrokes.current = 0;
      setResults(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const onInputValueChange = (value: any) => {
    lastNoOfKeyStrokes.current++;

    if (
      providerOptions.account &&
      inProgress === InteractionStatus.None &&
      value &&
      value.length > 3
    ) {
      lastQuery.current = value;
      const searchCb = () => search(value, lastNoOfKeyStrokes.current);
      setTimeout(searchCb, 400);
    } else {
      lastNoOfKeyStrokes.current = 0;
    }
  };

  const updatSelection = (event: any, checked: boolean, entry: any) => {
    if (checked) {
      const userSelection = [...usersToAdd];
      userSelection.push({
        ...entry,
        identityProvider: Constants.identityProvider.azureAD,
      });
      setUserCollection(userSelection);
    } else {
      const newSelection = usersToAdd.filter((user) => {
        return entry.id !== user.id;
      });
      setUserCollection(newSelection);
    }
  };

  const updateLevel = (levelId: any) => {
    const newLevel = validPermissionLevels.filter((l: any) => {
      return l.id === levelId;
    });

    if (newLevel.length === 1) setLevel(newLevel[0]);
  };

  const addUsers = () => {
    if (isExternalUsers && externalUser.trim().length > 0) {
      onAdd(
        [
          {
            displayName: externalUser,
            userPrincipalName: externalUser,
            identityProvider: Constants.identityProvider.email,
          },
        ],
        permissionLevel.id,
        Constants.identityProvider.email,
      );
    } else {
      onAdd(usersToAdd, permissionLevel.id, Constants.identityProvider.azureAD);
    }
    closeMe();
  };

  const closeMe = () => {
    setUserCollection([]);
    setResults([]);
    setExternalUser('');
    setIsExternalUsers(false);
    setLevel(validPermissionLevels[0]);
    onClose();
  };

  const showAddExternalUsers = () => {
    if (
      permissionContext !== null &&
      permissionContext.entity === 'Webcast' &&
      permissionContext.metadata !== null
    ) {
      const webcast = permissionContext.metadata;
      if (webcast !== null) {
        return !webcast.requireOrgSignIn.value && webcast.requireInvite.value;
      }
    }
    return false;
  };

  const getValidPermissionLevels = () => {
    if (
      permissionContext !== null &&
      permissionContext.entity === 'Webcast' &&
      permissionContext.metadata !== null
    ) {
      if (isExternalUsers && permissionContext.metadata.requireInvite?.value) {
        return validPermissionLevels.filter((_: any) => _.title === 'Viewer');
      }
    }
    return validPermissionLevels;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={show}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <span>Add user</span>
          </Grid>
          {showAddExternalUsers() && (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isExternalUsers}
                    onChange={(event, value) => {
                      setIsExternalUsers(value);
                      setResults([]);
                      setUserCollection([]);
                    }}
                    name="addExternalUsers"
                    color="primary"
                    disabled={false}
                  />
                }
                label="Add External Users"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      {isExternalUsers && (
        <DialogContent dividers={true} className="dialogContentHeight">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="externalUserName"
                name="externalUserName"
                label="Required email addresses and/or wildcard patterns for external sign in"
                value={externalUser}
                onChange={(event) => {
                  setExternalUser(event.target.value);
                }}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {permissionLevel && (
                <FormControl fullWidth>
                  <InputLabel id="externalPermLevel">Role</InputLabel>
                  <Select
                    labelId="externalPermLevel"
                    id="externalPermLevel-select"
                    value={permissionLevel.id}
                    label="Role"
                    onChange={(event) => updateLevel(event.target.value)}
                  >
                    {getValidPermissionLevels().map(
                      (level: any, index: any) => (
                        <MenuItem key={`level-${index}`} value={level.id}>
                          {level.title}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {!isExternalUsers && (
        <DialogContent dividers={true} className="dialogContentHeight">
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="search"
                name="search"
                label="Enter name to search for"
                onChange={(event) => onInputValueChange(event.target.value)}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {permissionLevel && (
                <FormControl fullWidth>
                  <InputLabel id="permLevel">Role</InputLabel>
                  <Select
                    labelId="permLevel"
                    id="permLevel-select"
                    value={permissionLevel.id}
                    label="Role"
                    onChange={(event) => updateLevel(event.target.value)}
                  >
                    {getValidPermissionLevels().map(
                      (level: any, index: any) => (
                        <MenuItem key={`level-${index}`} value={level.id}>
                          {level.title}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          {results.length > 0 && (
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((entry) => {
                    return (
                      <TableRow hover tabIndex={-1} key={entry.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onChange={(event, checked) =>
                              updatSelection(event, checked, entry)
                            }
                          />
                        </TableCell>
                        {columns.map((column) => {
                          const value = entry[column.id];
                          return <TableCell key={column.id}>{value}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          disabled={
            isExternalUsers
              ? externalUser.trim().length <= 0
              : usersToAdd.length === 0
          }
          onClick={() => addUsers()}
        >
          Add Selected
        </Button>
        <Button onClick={() => closeMe()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
